import React from "react";
import $ from "jquery";
import map from "../assets/PageMatterial/map.png";
import "../bars.css";

function Counters() {
  React.useEffect(() => {
    // Tüm .counter-value elementleri için animasyonu başlatıyoruz
    $(".counter-value").each(function () {
      // Hedef değeri text içeriği yerine data-target attribute’undan okuyoruz
      const targetValue = $(this).data("target") || 0;
      $(this)
        .prop("Counter", 0)
        .animate(
          {
            Counter: parseInt(targetValue, 10),
          },
          {
            duration: 5500,
            easing: "swing",
            step: function (now) {
              $(this).text(Math.ceil(now));
            },
          }
        );
    });
  }, []);

  const data = [
    { label: "OEM", value: 80, color: "#4a90e2" },
    { label: "Automotive", value: 75, color: "#5ba4e5" },
    { label: "Goods", value: 40, color: "#78c1e8" },
    { label: "Paper", value: 35, color: "#99d8eb" },
    { label: "Industrial", value: 45, color: "#c7eaf0" },
    { label: "Chemicals", value: 35, color: "#dff1f6" },
    { label: "Building", value: 25, color: "#f4f9fb" },
    { label: "Fashion", value: 30, color: "#f4f9fb" },
    { label: "Building", value: 15, color: "#f4f9fb" },
    { label: "Events", value: 10, color: "#f4f9fb" },
  ];

  return (
    <>
      {/* Üst kısım – harita, ülkeler, lokasyonlar, çalışanlar vs. */}
      <div className="bg-image-map mx-auto d-flex align-middle justify-center">
        <div className="row container mx-auto">
          {/* Sol Bölüm */}
          <div className="col-md-4 p-0 m-0">
            <div className="row col-12 col-md-12 pt-2 mt-5 mx-auto">
              <div className="bg-transparent pt-3">
                <div className="row mx-auto">
                  <img src={map} alt="map" className="img-fluid" />
                  <div className="row">
                    <div className="col-6 mx-auto">
                      {/* data-target ile animasyon hedefini belirtiyoruz */}
                      <div
                        className="counter-value counter-num text-center"
                        data-target="18"
                      >
                        0
                      </div>
                      <p
                        className="text-light text-center fw-bold fs-md-4"
                        style={{
                          marginTop: "-20px",
                          textShadow: "1px 1px 10px #00000059",
                        }}
                      >
                        Countries
                      </p>
                    </div>
                    <div className="col-6 mx-auto">
                      <div
                        className="counter-value counter-num text-center"
                        data-target="29"
                      >
                        0
                      </div>
                      <p
                        className="text-light text-center fw-bold fs-md-4"
                        style={{ marginTop: "-20px" }}
                      >
                        Locations
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row align-bottom mt-5 pt-4">
                  <div className="col-4 container mt-0">
                    <img
                      src="https://www.ewals.com/img/employees.png"
                      alt="employees"
                      className="img-fluid pt-md-1 pb-md-2"
                    />
                  </div>
                  <div className="col-7 employees-num">
                    <p
                      className="counter-value text-start"
                      data-target="2000"
                    >
                      0
                    </p>
                  </div>
                  <div className="col-12 row mx-auto">
                    <p className="text-light text-start fw-medium fs-4 col-12">
                      Employees
                    </p>
                    <p className="text-primary bg-main text-start fw-medium fs-6 col-4">
                      Office 1.200
                    </p>
                    <p className="text-primary bg-main-ins text-start fw-medium fs-6 col-4">
                      Office 1.200
                    </p>
                    <p className="text-primary bg-main text-start fw-medium fs-6 col-4">
                      Office 1.200
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Orta Bölüm */}
          <div className="row col-12 col-md-5">
            <div className="bg-transparent p-1 m-2">
              <div className="bg-transparent col-md-9 p-1 m-5">
                <p className="text-light fw-bolder fs-4 m-0 p-0">
                  International goods transport
                </p>
                <p className="text-light m-0 p-0">60% intermodal</p>
                <p className="text-light m-0 p-0">40% Road</p>
                <hr className="text-light border-3" />
                <p className="text-light m-0 p-0 lh-1">
                  148,000 Rail/Short-sea transfers used yearly
                </p>
                <hr className="text-light border-3" />
                <p className="text-light m-0 p-0 lh-1">
                  Across approx. 165 unique Rail/hort-sea connections
                </p>
              </div>
            </div>
            <div className="bg-transparent p-md-1 m-md-2 mb-md-5">
              <div className="chart-container">
                <h2 className="text-light">% of Total TO Per Industry</h2>
                <div className="chart">
                  {data.map((item, index) => (
                    <div
                      key={index}
                      className="bar"
                      style={{
                        "--value": item.value,
                        "--color": item.color,
                      }}
                      data-value={item.label}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Sağ Bölüm */}
          <div className="row col-12 col-md-3 mx-auto p-0">
            <div className="bg-transparent p-1 m-2 mt-5">
              <div className="bg-transparent p-1 m-0 mt-md-4">
                <p
                  className="counter-value employees-num"
                  data-target="3050"
                >
                  0
                </p>
                <p className="fw-thin fs-md-4 m-0 p-0 text-light">
                  Loads per day
                </p>
              </div>
            </div>
            <div className="bg-transparent p-1 m-2">
              <div className="row align-bottom">
                <div className="col-4 container d-flex align-middle">
                  <img
                    src="https://www.ewals.com/img/trailer.png"
                    alt="trailer"
                    className="img-fluid pt-md-1 pb-md-2"
                  />
                </div>
                <div className="col-7 align-middle employees-num">
                  <p
                    className="counter-value text-start m-0 p-0 d-flex"
                    data-target="4400"
                  >
                    0
                  </p>
                </div>
                <div className="col-12 row mx-auto">
                  <p className="text-light text-start fw-thin fs-md-4 col-12">
                    Trailers
                  </p>
                  <p className="text-light text-start fw-medium fs-md-6 col-12 p-0 m-0">
                    1.050 Mega Huckepack XL trailers
                  </p>
                  <p className="text-light text-start fw-medium fs-md-6 col-12 p-0 m-0">
                    3.350 Mega Huckepack XLS trailers
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-transparent p-1 m-2">
              <div className="row align-bottom">
                <div className="col-md-5 col-5 align-bottom employees-num">
                  <p
                    className="counter-value text-start mx-md-3 mx-3 p-0"
                    data-target="2050"
                  >
                    0
                  </p>
                </div>
                <div className="col-md-5 col-5 container">
                  <img
                    src="https://www.ewals.com/img/truck.png"
                    alt="truck"
                    className="img-fluid pt-md-1 pb-md-2 p-md-0"
                  />
                </div>
                <div className="col-12 row mx-auto">
                  <p className="text-light text-start fw-thin fs-4 col-12">
                    Trucks
                  </p>
                  <p className="text-light text-start fw-medium fs-6 col-12 p-0 m-0">
                    1.050 Mega Huckepack XL trailers
                  </p>
                  <p className="text-light text-start fw-medium fs-6 col-12 p-0 m-0 mb-5 mb-md-0">
                    700 Own tractor units
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Alt Kısım – Diğer counter blokları */}

    </>
  );
}

export default Counters;
