// airfreight.js
import React  from "react";
import { useState } from "react";
import { Link } from "react-router-dom"; // Yönlendirme için React Router'dan Link bileşeni
// Gerekli görseller (varsa kendi projenizdeki görselleri kullanın)
import { Modal, Button, Form } from "react-bootstrap";
import Footer from "../components/Footer";
import Headers from "../components/Header";
import Maps from "../components/Maps";

function RequestOfferModal({ show, handleClose }) {


  const [formData, setFormData] = useState({
    businessName: "",
    businessAddress: "",
    fullName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://mail.grupmers.com/send_email.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (result.status === "success") {
        alert("Teklif isteğiniz başarıyla gönderildi!");
        handleClose(); // Modalı kapat
      } else {
        alert("Bir hata oluştu: " + result.message);
      }
    } catch (error) {
      console.error("İstek gönderilirken hata oluştu:", error);
      alert("Bir hata oluştu, lütfen tekrar deneyin.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title className="text-primary fw-bold">Bir Teklif Alın</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" required>
          <Form.Label>İşletme ismi</Form.Label>
          <Form.Control
            type="text"
            name="businessName"
            placeholder="İşletme ismi..."
            value={formData.businessName}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>İşletme Adresi</Form.Label>
          <Form.Control
            type="text"
            name="businessAddress"
            placeholder="İş yeri adresi..."
            value={formData.businessAddress}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>İsim Soyisim</Form.Label>
          <Form.Control
            type="text"
            name="fullName"
            placeholder="İsim soyisim giriniz..."
            value={formData.fullName}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>E-Posta</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="e-posta adresinizi giriniz..."
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Telefon numarası</Form.Label>
          <Form.Control
            type="tel"
            name="phone"
            placeholder="Numaranızı giriniz..."
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Mesajınız</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="message"
            placeholder="Mesajınızı yazınız..."
            value={formData.message}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleClose}>
            Kapat
          </Button>
          <Button variant="outline-primary" type="submit">
            Gönder
          </Button>
        </Modal.Footer>
      </Form>
    </Modal.Body>
  </Modal>
  );
}

function Contact() { 
  const [modalShow, setModalShow] = useState(false);
    
  return (


    <div className="airfreight-page bg-light">     
      <Headers/>
      <Maps className="h-25 d-inline-block"></Maps>
      <div className='mx-auto col-12 pt-5 text-center' >
         <h1 className='mx-auto text-center highlighted-text fs-2 fw-bold'  > Contact us </h1>
         </div>

      {/* BİLGİLENDİRME BÖLÜMÜ */}
      <section className="info-section container p-5">
        <div className="container">
          <div className="row align-items-center">
            {/* Sol: İkon/Görsel */}
            <div className="col-md-6">          
              <p className="text-dark col-12">
              We aim to provide you with the perfect product fit for your local and European supply chains. Get in touch with us, and experience our eagerness to discuss and find solutions for your complex requests, and to offer you quick and comprehensible feedback for your straightforward transport requests!
              </p>                  
            </div>
            {/* Sağ: Açıklama ve Liste */}
            <div className="col-md-6">              
              <p className="text-dark col-12">
              Please click on a marker in the map above to see all office information and to contact your local Ewals Cargo Care office. Are you looking for career opportunities? We welcome you to send your open application on our career page. Do you want to partner up with us to serve customers, then please go to partners.
              </p>                        
            </div>
          </div>
        </div>
      </section>

      <div className="col-12 col-md-6 d-flex justify-content-center mx-auto">
              <Button variant="primary" onClick={() => setModalShow(true)} className="btn btn-lg btn-primary border-warning rounded-0 fw-semibold text-start w-50">
                Bir Teklif Alın
              </Button>
            </div>

      {/* CALL-TO-ACTION (CTA) BÖLÜMÜ */}
      <section className="cta-section bg-white text-center py-5 mb-5 mt-5">
              <div className="container pb-5 mt-n5">
                <h2>Gönderim Yapmaya Hazır Mısınız?</h2>
                <p>Bizimle Hemen İletişime Geçerek Gönderilerinize Hızla Çözüm Bulabilirsiniz.</p>
                <Link to="/contact" className="btn btn-primary">
                  Bize Ulaşın
                </Link>
              </div>
            </section>
      <RequestOfferModal show={modalShow} handleClose={() => setModalShow(false)} />
      <Footer></Footer>
    </div>
    
  );
}

export default Contact;
