// airfreight.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import Footer from "../components/Footer";
import Headers from "../components/Header";
import Brands from "../components/Brands";

import Airvid from "../assets/GroundFreight/trucks-highway-mountain-sunset.jpg"
import planeIcon from "../assets/AirFreight/cargo-plane.jpg"
import TerminalPhoto from "../assets/Ocean Freight/logistic-terminal.jpg"
import AirFreightPhoto from "../assets/AirFreight/OJK91T0.jpg"
import OceanFreightPhoto from "../assets/Ocean Freight/container-operation-port-series.jpg"




function RequestOfferModal({ show, handleClose }) {


  const [formData, setFormData] = useState({
    businessName: "",
    businessAddress: "",
    fullName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://mail.grupmers.com/send_email.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (result.status === "success") {
        alert("Teklif isteğiniz başarıyla gönderildi!");
        handleClose(); // Modalı kapat
      } else {
        alert("Bir hata oluştu: " + result.message);
      }
    } catch (error) {
      console.error("İstek gönderilirken hata oluştu:", error);
      alert("Bir hata oluştu, lütfen tekrar deneyin.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title className="text-primary fw-bold">Bir Teklif Alın</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" required>
          <Form.Label>İşletme ismi</Form.Label>
          <Form.Control
            type="text"
            name="businessName"
            placeholder="İşletme ismi..."
            value={formData.businessName}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>İşletme Adresi</Form.Label>
          <Form.Control
            type="text"
            name="businessAddress"
            placeholder="İş yeri adresi..."
            value={formData.businessAddress}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>İsim Soyisim</Form.Label>
          <Form.Control
            type="text"
            name="fullName"
            placeholder="İsim soyisim giriniz..."
            value={formData.fullName}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>E-Posta</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="e-posta adresinizi giriniz..."
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Telefon numarası</Form.Label>
          <Form.Control
            type="tel"
            name="phone"
            placeholder="Numaranızı giriniz..."
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Mesajınız</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="message"
            placeholder="Mesajınızı yazınız..."
            value={formData.message}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleClose}>
            Kapat
          </Button>
          <Button variant="outline-primary" type="submit">
            Gönder
          </Button>
        </Modal.Footer>
      </Form>
    </Modal.Body>
  </Modal>
  );
}





function ServicesPage() {
  const [modalShow, setModalShow] = useState(false);
    
  return (
    


    <div className="bg-white">
      {/* HERO BÖLÜMÜ */}
      <Headers/>
      <section
        className="hero-section text-center text-white"
        style={{
          background: `url(${Airvid}) no-repeat center center/cover`,
          padding: "270px 0",
            position: "relative", /* Sit on top of the page content */
            display: "block",/* Hidden by default */
            width: "100%", /* Full width (cover the whole page) */
            height: "100%", /* Full height (cover the whole page) */
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor:" rgba(0,0,0,0.9)", /* Black background with opacity */
            zIndex: 0, /* Specify a stack order in case you're using a different order for other elements */
            cursor: "pointer", /* Add a pointer on hover */ }}>     
      </section>

      <Brands/>

      <div className='mx-auto col-12 pt-5 text-center' >
         <h1 className='mx-auto text-center highlighted-text fs-1 fw-semibold '  > Hizmetler </h1>
         </div>

         <section> 
         <div className="container row mx-auto">
         <div className="col-md-9 row mx-auto"> 

         <div className="col-md-6">
         <p className="px-2" style={{fontSize:"13px"}}>Moving your cargo from A to B with our wide and versatile product range, that’s our expertise.</p>

         </div>
         <div className="col-md-6">
         <p className="px-2" style={{fontSize:"13px"}}>Moving you as a preferred and reliable partner, that’s our ultimate objective. Discover below how we move the burden of your desk!</p>

         </div>


         </div>
         </div>
         
         </section>

      <section className="hero-section text-center text-white ">
      {/* BİLGİLENDİRME BÖLÜMÜ */}
      <section className=" bg-white py-5">
        <div className="container py-5">
          <div className="row align-items-center">
            {/* Sol: İkon/Görsel */}
            <div className="col-md-7 p-4 order-1">
              <img src={planeIcon} alt="Air Freight" className="img-fluid w-100 shadow " />
            </div>
            {/* Sağ: Açıklama ve Liste */}
            <div className="col-md-5 p-5 order-0 " >
              <h2 className="text-primary text-start fw-bold fs-1">Why Choose Our Air Freight?</h2>
              <p className="text-dark text-start fw-bold">
                Our air freight service offers the fastest delivery, efficient customs clearance, and real-time tracking. With our global network, we ensure your cargo is delivered on time, every time.
              </p>
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3">
              <Button variant="primary" onClick={() => setModalShow(true)} className="btn btn-lg btn-primary border-warning rounded-0 fw-semibold text-start w-100">
                Bir Teklif Alın
              </Button>
            </div>            
            </div>
          </div>
          <section>
            <div className="container row mx-auto mt-5 pt-5">            

              <div className="col-md-4 px-3">
                <div style={{   
                background: `url(${Airvid}) no-repeat center center/cover`,                
                padding: "110px 0",
                position: "relative", /* Sit on top of the page content */
                display: "block",/* Hidden by default */
                width: "100%", /* Full width (cover the whole page) */
                height: "100%", /* Full height (cover the whole page) */
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,    }}   >
                <h2 className="text-light fw-semibold lh-1" style={{textShadow:"0px 0px 4px rgb(0, 51, 217)"}}>Why Choose Our Ground Freight?</h2>
                </div>               
              </div>

              <div className="col-md-4 px-3">
                <div   style={{   
                  background: `url(${TerminalPhoto}) no-repeat center center/cover`,
              padding: "100px 0",
                position: "relative", /* Sit on top of the page content */
                display: "block",/* Hidden by default */
                width: "100%", /* Full width (cover the whole page) */
                height: "100%", /* Full height (cover the whole page) */
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,  
                  }}   >
                <h2 className="text-light fw-semibold lh-1" style={{textShadow:"0px 0px 4px rgb(0, 0, 0)"}}>How Ferpinta increased its loading volume by 12%?</h2>

                </div>               
              </div>
              <div className="col-md-4 px-3">
                <div style={{   
                  background: `url(${AirFreightPhoto}) no-repeat center center/cover`,
              padding: "120px 0",
                position: "relative", /* Sit on top of the page content */
                display: "block",/* Hidden by default */
                width: "100%", /* Full width (cover the whole page) */
                height: "100%", /* Full height (cover the whole page) */
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,    }}   >
                <h2 className="text-light fw-semibold lh-1" style={{textShadow:"0px 0px 4px rgb(0, 0, 0)"}}>Holmen's journey to a more sustainable supply chain</h2>
              
                </div>               
              </div>                                    
            
            </div>
          </section>
          

          <div className="row align-items-center py-5 mt-5 ">
            {/* Sol: İkon/Görsel */}
            <div className="col-md-7 p-4">
              <img src={OceanFreightPhoto} alt="Air Freight" className="img-fluid w-100 shadow" />
            </div>
            {/* Sağ: Açıklama ve Liste */}
            <div className="col-md-5 text-start px-3">
              <h2 className="text-primary fw-bold fs-2">Moving your part loads flexibly and efficiently</h2>            
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3">
              <Button variant="primary" onClick={() => setModalShow(true)} className="btn btn-lg btn-primary border-warning rounded-0 fw-semibold text-start w-100">
                Bir Teklif Alın
              </Button>
            </div>
            <RequestOfferModal show={modalShow} handleClose={() => setModalShow(false)} />
            </div>
          </div>
        </div>
      </section>

      <section>
            <div className="container row mx-auto ">            

              <div className="col-md-4 px-3">
                <div style={{   
                background: `url(${Airvid}) no-repeat center center/cover`,                
                padding: "110px 0",
                position: "relative", /* Sit on top of the page content */
                display: "block",/* Hidden by default */
                width: "100%", /* Full width (cover the whole page) */
                height: "100%", /* Full height (cover the whole page) */
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,    }}   >
                <h2 className="text-light fw-semibold lh-1" style={{textShadow:"0px 0px 4px rgb(0, 51, 217)"}}>Why Choose Our Ground Freight?</h2>
                </div>               
              </div>

              <div className="col-md-4 px-3">
                <div   style={{   
                  background: `url(${TerminalPhoto}) no-repeat center center/cover`,
              padding: "100px 0",
                position: "relative", /* Sit on top of the page content */
                display: "block",/* Hidden by default */
                width: "100%", /* Full width (cover the whole page) */
                height: "100%", /* Full height (cover the whole page) */
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,  
                  }}   >
                <h2 className="text-light fw-semibold lh-1" style={{textShadow:"0px 0px 4px rgb(0, 0, 0)"}}>How Ferpinta increased its loading volume by 12%?</h2>

                </div>               
              </div>
              <div className="col-md-4 px-3">
                <div style={{   
                  background: `url(${AirFreightPhoto}) no-repeat center center/cover`,
              padding: "120px 0",
                position: "relative", /* Sit on top of the page content */
                display: "block",/* Hidden by default */
                width: "100%", /* Full width (cover the whole page) */
                height: "100%", /* Full height (cover the whole page) */
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,    }}   >
                <h2 className="text-light fw-semibold lh-1" style={{textShadow:"0px 0px 4px rgb(0, 0, 0)"}}>Holmen's journey to a more sustainable supply chain</h2>
              
                </div>               
              </div> 

              <div className="row align-items-center py-5 mt-5 ">
            {/* Sol: İkon/Görsel */}
            <div className="col-md-7 p-4 order-1">
              <img src={Airvid} alt="Air Freight" className="img-fluid w-100 shadow" />
            </div>
            {/* Sağ: Açıklama ve Liste */}
            <div className="col-md-5 text-start px-3">
              <h2 className="text-primary fw-bold fs-2">Your cross-border logistics network with local expertisey</h2>            
              <div className="col-12 col-md-6 d-flex justify-content-center mt-3">
              <Button variant="primary" onClick={() => setModalShow(true)} className="btn btn-lg btn-primary border-warning rounded-0 fw-semibold text-start w-100">
                Bir Teklif Alın
              </Button>
            </div>
            <RequestOfferModal show={modalShow} handleClose={() => setModalShow(false)} />
            </div>
          </div>                                   
            

          <div className="col-md-4 px-3">
                <div style={{   
                background: `url(${Airvid}) no-repeat center center/cover`,                
                padding: "110px 0",
                position: "relative", /* Sit on top of the page content */
                display: "block",/* Hidden by default */
                width: "100%", /* Full width (cover the whole page) */
                height: "100%", /* Full height (cover the whole page) */
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,    }}   >
                <h2 className="text-light fw-semibold lh-1" style={{textShadow:"0px 0px 4px rgb(0, 51, 217)"}}>Why Choose Our Ground Freight?</h2>
                </div>               
              </div>

              <div className="col-md-4 px-3">
                <div   style={{   
                  background: `url(${TerminalPhoto}) no-repeat center center/cover`,
              padding: "100px 0",
                position: "relative", /* Sit on top of the page content */
                display: "block",/* Hidden by default */
                width: "100%", /* Full width (cover the whole page) */
                height: "100%", /* Full height (cover the whole page) */
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,  
                  }}   >
                <h2 className="text-light fw-semibold lh-1" style={{textShadow:"0px 0px 4px rgb(0, 0, 0)"}}>How Ferpinta increased its loading volume by 12%?</h2>

                </div>               
              </div>
              <div className="col-md-4 px-3">
                <div style={{   
                  background: `url(${AirFreightPhoto}) no-repeat center center/cover`,
              padding: "120px 0",
                position: "relative", /* Sit on top of the page content */
                display: "block",/* Hidden by default */
                width: "100%", /* Full width (cover the whole page) */
                height: "100%", /* Full height (cover the whole page) */
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,    }}   >
                <h2 className="text-light fw-semibold lh-1" style={{textShadow:"0px 0px 4px rgb(0, 0, 0)"}}>Holmen's journey to a more sustainable supply chain</h2>
              
                </div>               
              </div> 


            </div>
          </section>

      </section>
      

      {/* CALL-TO-ACTION (CTA) BÖLÜMÜ */}
      <section className="cta-section bg-light text-center py-5 mt-5">
        <div className="container">
          <h2>Ready to Ship?</h2>
          <p>Contact us today and let our experts take care of your air freight needs.</p>
          <Link to="/contact" className="btn btn-primary">
            Contact Us
          </Link>
        </div>
      </section>
      <Footer></Footer>
    </div>
    
  );
}

export default ServicesPage;
