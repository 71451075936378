// airfreight.js
import React from "react";
import { Link } from "react-router-dom"; // Yönlendirme için React Router'dan Link bileşeni
// Gerekli görseller (varsa kendi projenizdeki görselleri kullanın)
import Footer from "../components/Footer";
import Headers from "../components/Header";
import Airvid from "../assets/AirFreight/cargo-plane.jpg"

import planeIcon from "../assets/PageMatterial/plane.png";


function OceanFreight() {
    
  return (


    <div className="airfreight-page">
      {/* HERO BÖLÜMÜ */}
      <Headers/>
      <section
        className="hero-section text-center text-white"
        style={{
          background: `url(${Airvid}) no-repeat center center/cover`,
          padding: "200px 0",
            position: "relative", /* Sit on top of the page content */
            display: "block",/* Hidden by default */
            width: "100%", /* Full width (cover the whole page) */
            height: "100%", /* Full height (cover the whole page) */
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor:" rgba(0,0,0,0.9)", /* Black background with opacity */
            zIndex: 2, /* Specify a stack order in case you're using a different order for other elements */
            cursor: "pointer", /* Add a pointer on hover */

        }}
      >
        <div className="container">
          <h1 className="display-4">Air Freight Solutions</h1>
          <p className="lead">
            Fast, reliable, and cost-effective air freight services to connect your business globally.
          </p>
          <Link to="/contact" className="btn btn-primary btn-lg mt-4">
            Get a Quote
          </Link>
        </div>
      </section>

      {/* BİLGİLENDİRME BÖLÜMÜ */}
      <section className="info-section py-5">
        <div className="container">
          <div className="row align-items-center">
            {/* Sol: İkon/Görsel */}
            <div className="col-md-6">
              <img src={planeIcon} alt="Air Freight" className="img-fluid" />
            </div>
            {/* Sağ: Açıklama ve Liste */}
            <div className="col-md-6">
              <h2 className="text-light">Why Choose Our Air Freight?</h2>
              <p className="text-light">
                Our air freight service offers the fastest delivery, efficient customs clearance, and real-time tracking. With our global network, we ensure your cargo is delivered on time, every time.
              </p>
              <ul className="text-light">
                <li>Fast transit times</li>
                <li>Secure handling</li>
                <li>Customizable solutions</li>
                <li>Real-time tracking</li>
              </ul>
              <Link to="/services" className="btn btn-primary p-2 px-5 mt-3">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* CALL-TO-ACTION (CTA) BÖLÜMÜ */}
      <section className="cta-section bg-light text-center py-5">
        <div className="container">
          <h2>Ready to Ship?</h2>
          <p>Contact us today and let our experts take care of your air freight needs.</p>
          <Link to="/contact" className="btn btn-primary">
            Contact Us
          </Link>
        </div>
      </section>
      <Footer></Footer>
    </div>
    
  );
}

export default OceanFreight;
