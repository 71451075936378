import React from "react";

function Video() {
  return (
    <div className="bg-main-ins pb-5">
      <div className="bg-main-ins pb-5">
        <div className="bg-main-ins pb-5">
          <div className="container mx-auto p-md-5">
            <iframe
              className="px-md-5 video-youtube mb-5 mt-5"
              src="https://www.youtube.com/embed/4VpFUPvKYMM?si=yTKYk1cGbbTPe-4r"
              title="Mers Video"
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" 
              allowFullScreen 
            ></iframe>

          </div>

          <div className="mx-auto col-12 pt-5 text-center">
            <h1 className="mx-auto text-center highlighted-text fs-1 fw-semibold">
              News & stories
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Video;
