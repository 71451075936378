// airfreight.js
import React from "react";
import { Link } from "react-router-dom"; // Yönlendirme için React Router'dan Link bileşeni
// Gerekli görseller (varsa kendi projenizdeki görselleri kullanın)
import Footer from "../components/Footer";
import Headers from "../components/Header";
import AboutSlide from "../assets/about.jpeg"
import News from "../components/News";
import Counters from "../components/Counters";



function AboutUs() {
    
  return (


    <div className="Aboutus-page bg-light bg-opacity-100">
      {/* HERO BÖLÜMÜ */}
      <Headers/>
      <section
        className="hero-section text-center text-light"
        style={{
          background: `url(${AboutSlide}) no-repeat center center/cover`,
          padding: "250px 0",
            position: "relative", /* Sit on top of the page content */
            display: "block",/* Hidden by default */
            width: "100%", /* Full width (cover the whole page) */
            height: "100%", /* Full height (cover the whole page) */
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor:" rgba(0,0,0,0.9)", /* Black background with opacity */
            zIndex: 2, /* Specify a stack order in case you're using a different order for other elements */
            cursor: "pointer", /* Add a pointer on hover */          

        }}
      >
        <div className="container">
        <div className="col-12 col-md-4 float-end rounded p-0 bg-primary bg-opacity-75  " >
        <h1 className="display-6 text-light text-shadow fw-bold opacity-100 mt-4 " >Şirket Profili</h1>
          <p className="lead text-light fs-6 fw-normal px-5 mt-1 ">
          Grup Mers Lojistik 2014 yılında uluslararası taşımacılık alanında faaliyette bulunmak amacıyla kurulmuştur. Kurulduğu günden itibaren kısa bir zaman dilimi içersinde; deneyimli ekibi, sunmuş olduğu hizmet kalitesiyle, global ve kurumsal şirketlerin tercihi olmuştur.
          </p>
        </div>
        </div>
    
      </section>
      <div className='mx-auto col-12 pt-5 mt-5 mb-3 text-center' >
         <h1 className='mx-auto text-center highlighted-text fs-2 fw-medium'  > Grup Mers Lojistik </h1>
         </div>

      {/* BİLGİLENDİRME BÖLÜMÜ */}
      <section className="info-section ">
        <div className="container pb-5">
          <div className="row align-items-center">
            {/* Sol: İkon/Görsel */}
            
            {/* Sağ: Açıklama ve Liste */}
            
            <div className="col-md-12 ">              
              <p className="text-dark fw-semibold">
              Grup Mers Lojistik 2014 yılında uluslararası taşımacılık alanında faaliyette bulunmak amacıyla kurulmuştur. Kurulduğu günden itibaren kısa bir zaman dilimi içersinde; deneyimli ekibi, sunmuş olduğu hizmet kalitesiyle, global ve kurumsal şirketlerin tercihi olmuştur.
              </p>
              <p className="text-dark">
              Uluslararası nakliyede Avrupa ülkeleriyle karayolu uzmanlığı başta olmak üzere, denizyolu, havayolu, demiryolu ve ağır nakliye alanlarında dünyanın her yerinden hizmet vermektedir.
              </p>
              <p className="text-dark">
              Grup Mers Lojistik, her zaman müşteri odaklı çalışarak, organizasyon yapısındaki etkin iletişim ağıyla, sürekli kendini eğiterek ve geliştirerek çalışan ekibiyle, güvenli ve kaliteli yük taşımacılığında öncü kuruluşlardandır. Taşımacılık faaliyetlerinin her aşamasını teknolojik olarak takip ederek, malın zamanında alıcısına hasarsız ve eksiksiz teslimini sağlamaktadır. Filosunda sahip olduğu alternatif treyler imkanları ve müşterilerine özel geliştirdiği taşımacılık çözümleriyle terminli yük taşımacılındaki liderliğini sürdürmektedir.
              </p>
              <p className="text-dark">
              Araç filosunu uydu takip sistemi ile anlık görüntüleyebilmekte ve tüm faaliyetlerini özel bir yazılım üzerinden sürdürerek ayrıntılı raporlama yapabilmektedir.
              </p>            
              
            </div>
          </div>
        </div>
      </section>

      {/* CALL-TO-ACTION (CTA) BÖLÜMÜ */}
      <section className="cta-section bg-white text-center py-5 mb-5">
        <div className="container pb-5 mt-n5">
          <h2>Gönderim Yapmaya Hazır Mısınız?</h2>
          <p>Bizimle Hemen İletişime Geçerek Gönderilerinize Hızla Çözüm Bulabilirsiniz.</p>
          <Link to="/contact" className="btn btn-primary">
            Bize Ulaşın
          </Link>
        </div>
      </section>
      <News ></News>
      <Counters></Counters>

      <Footer></Footer>
    </div>
    
  );
}

export default AboutUs;
