import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function BlogsList() {
  const [posts, setPosts] = useState([]); // Tüm bloglar
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 8; // Her sayfada kaç blog gösterilsin?
  const imageSrc = "../../assets/";

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch("/blogs.json"); 

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setPosts(data.posts);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      }
    };

    fetchPosts();
  }, []);

  // Sayfa numaralarını hesapla
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(posts.length / postsPerPage);

  // Sayfa değiştir
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="bg-light mx-auto">
      <div className="container mx-auto">
        <div className="mx-auto col-12 pt-5 text-center">
          <h1 className="mx-auto text-center highlighted-text fs-1 fw-semibold">
            Blog Yazılarımız
          </h1>
        </div>

        {/* Blog Kartları */}
        <div className="row container mx-auto py-4">
          {currentPosts.map((post) => (
            <div key={post.id} className="col-12 col-sm-6 col-md-3 mx-auto pb-3">
              <div className="card" 
                style={{ minWidth: "16rem", maxWidth: "22rem", boxShadow: "#d2d2d3 10px 10px 10px", minHeight: "26rem", maxHeight: "26rem" }}>
                <img className="card-img-top" src={`${imageSrc}${post.image}`} alt={post.title}
                  style={{ objectFit: "cover", width: "100%", maxHeight: "170px", height: "170px" }} />
                <div className="card-body">
                  <h5 className="card-title fs-6 fw-bold text-primary">{post.title}</h5>
                  <p className="card-text">{post.content}</p>
                </div>
                <div className="text-center mb-3 pb-2">
                <Link to={`/blog/${post.id}`} className="btn btn-outline-primary"> Detaylarını incele</Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Sayfalama Butonları */}
        <div className="text-center py-3 pb-5">
          <button className="btn btn-outline-primary mx-2" onClick={goToPrevPage} disabled={currentPage === 1}>
            Önceki
          </button>
          <span className="mx-3">Sayfa {currentPage} / {totalPages}</span>
          <button className="btn btn-outline-primary mx-2" onClick={goToNextPage} disabled={currentPage === totalPages}>
            Sonraki
          </button>
        </div>
      </div>
    </div>
  );
}

export default BlogsList;
