import React, { useState } from "react";
import logo from '../assets/PageMatterial/mersLogo.png';
import { Link } from "react-router-dom";

function Footer (){

    const [formData, setFormData] = useState({       
        email: ""       
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await fetch("https://mail.grupmers.com/footer_email.php", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          });
    
          const result = await response.json();
    
          if (result.status === "success") {
            alert("Teklif isteğiniz başarıyla gönderildi!");           
          } else {
            
            alert("Bir hata oluştu: " + result.message);
          }
        } catch (error) {
          console.error("İstek gönderilirken hata oluştu:", error);
          alert("Bir hata oluştu, lütfen tekrar deneyin.");
        }
      };

    return(
        <div className="bg-transparent" style={{ position:"relative", zIndex:"15"}}>
        {/* <div className="shapedividers_com-2199"  style={{marginTop:"-60px", position:"relative", zIndex:"15", scale:"2"}}  >.</div> */}
        <div className="bg-dark">
        <div className="container row p-md-3 pb-0 mx-auto text-center">
        <div className="col-md-6 col-sm-12 text-white text-center">
            <section className="mb-md-4 mt-5">
             
                 <iframe 
                 className="mt-5 pt-5 col-12 col-md-10"
                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14644.418953221348!2d29.181606109110277!3d40.972447560124124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cacfcece18baa1%3A0x172b43366c59df36!2sMers%20Grup%20Lojistik%20T%C3%BCrkiye!5e1!3m2!1str!2str!4v1733387672519!5m2!1str!2str"                
                 height={"300"}
                 style={{borderRadius:"5px"}}
                 title="Mers Lojistik tanıtım videosu"
                 
                 ></iframe>
            </section>
        </div>
        <div className="col-md-2 col-sm-12 mt-md-5 text-white text-center ">
        <h2 className="fs-5 mt-5 pt-md-4 " > Quick links</h2>
       
           <Link 
              className="text-light mx-2 a p-3 text-center"
            style={{ textDecoration: 'none' }}
            to="/"> Anasayfa  </Link> 
           <Link 
              className="text-light mx-2 a p-3 text-center"
            style={{ textDecoration: 'none' }}
            to="/AboutUs"> Hakkımızda  </Link> 
           <Link 
              className="text-light mx-2 a p-3 text-center"
            style={{ textDecoration: 'none' }}
            to="/Career"> Kariyer  </Link> 
           <Link 
              className="text-light mx-2 a p-3 text-center"
            style={{ textDecoration: 'none' }}
            to="/Contact"> Bize Ulaşın  </Link>                    
          
        </div>
        <div className="col-md-4 col-sm-12 mt-5 text-white text-center ">
        <h2 className="fs-5 mt-5 pt-md-4 mb-md-5 " > Talk to Us</h2>
            <a href="#asd" className="a p-0 text-center" > Subscribe to our newsletter</a>           
            <a href="#asd" className="a p-1 mb-2 text-center " > Monthly digest of whats new and exciting from us.</a>      
                
            <form className="form-inline  col-11 mx-auto" onSubmit={handleSubmit}>  
  <div className="form-group  mb-2">    
    <input type="email" name="email" className="form-control" id="footermail" value={formData.email} onChange={handleChange} placeholder="E-posta adresinizi giriniz"/>
  </div>
  <button type="submit" className="btn btn-primary mb-2 col-4 offset-8"> Gönder </button>
</form>

   <img
    src={logo}
    alt="Logo"
    width={"250"}/>                      
            </div>
            <div className="text-secondary"> Powered by <Link className="text-decoration-none fw-bold" to="https://www.neomani.com" target="_blank" style={{color:"#f3742a"}}> neomani.com </Link></div>
            
        </div>
      
    </div>
</div>

   

    )
}

// Section
// Home
// Features
// Pricing
// FAQs
// About



     
export default Footer 