import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/PageMatterial/mersLogo.png';
import Button from 'react-bootstrap/Button';

function Headers() {
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  // Scroll event listener'ı ekleyip kaldırmak için useEffect kullanıyoruz.
  useEffect(() => {
    const scrollFunction = () => {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById('header').classList.add('header_scrolling');
        document.getElementById('header').classList.remove('bg-transparent');
      } else {
        document.getElementById('header').classList.remove('header_scrolling');
        document.getElementById('header').classList.add('bg-transparent');
      }
    };

    window.addEventListener('scroll', scrollFunction);
    return () => {
      window.removeEventListener('scroll', scrollFunction);
    };
  }, []);

  return (
    <nav
      id="header"
      className="navbar navbar-expand-lg navbar-primary bg-transparent fixed-top"
    >
      <div className="container">
        {/* Logo */}
        <Link className="navbar-brand" to="/" onClick={() => handleButtonClick('home')}>
          <img
            src={logo}
            alt="Logo"
            width="250"
            height="65"
            className="d-inline-block align-top"
          />
        </Link>

        {/* Toggle Button for Mobile */}
        <Button
          className="navbar-toggler bg-primary"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </Button>

        {/* Navbar Links */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto mobilenav">
            <li className="nav-item mx-1">
              <Link
                className={`nav-link text-light btn btn-outline-primary ${
                  activeButton === 'home' ? 'active' : ''
                }`}
                onClick={() => handleButtonClick('home')}
                to="/"
              >
                Anasayfa
              </Link>
            </li>
            <li className="nav-item mx-1">
              <Link
                className={`nav-link text-light btn btn-outline-primary ${
                  activeButton === 'about' ? 'active' : ''
                }`}
                onClick={() => handleButtonClick('about')}
                to="/AboutUs"
              >
                Hakkımızda
              </Link>
            </li>
            <li className="nav-item mx-1">
              
            <Link
                className={`nav-link text-light btn btn-outline-primary ${
                  activeButton === 'services' ? 'active' : ''
                }`}
                onClick={() => handleButtonClick('services')}
                to="/ServicesPage"
              >                  
                    Hizmetler
                  </Link>                              
            </li>
            <li className="nav-item mx-1">
              <Link
                className={`nav-link text-light btn btn-outline-primary ${
                  activeButton === 'partners' ? 'active' : ''
                }`}
                onClick={() => handleButtonClick('partners')}
                to="/Career"
              >
                Markalarımız
              </Link>
            </li>
            <li className="nav-item mx-1">
              <Link
                className={`nav-link text-light btn btn-outline-primary ${
                  activeButton === 'career' ? 'active' : ''
                }`}
                onClick={() => handleButtonClick('career')}
                to="/Career"
              >
                Kariyer
              </Link>
            </li>
            <li className="nav-item mx-1">
              <Link
                className={`nav-link text-light btn btn-outline-primary ${
                  activeButton === 'news' ? 'active' : ''
                }`}
                onClick={() => handleButtonClick('news')}
                to="/Blogs"
              >
                Haberler
              </Link>
            </li>
            <li className="nav-item mx-1">
              <Link
                className={`nav-link text-light btn btn-outline-primary ${
                  activeButton === 'contact' ? 'active' : ''
                }`}
                onClick={() => handleButtonClick('contact')}
                to="/Contact"
              >
                Bize Ulaşın
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Headers;
