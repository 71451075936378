import React from "react";
import { Link } from "react-router-dom"; // React Router'dan Link import ediliyor

import arrow from "../assets/PageMatterial/arrow.png";
import plane from "../assets/PageMatterial/plane.png";
import ship from "../assets/PageMatterial/ship.png";
import truck from "../assets/PageMatterial/truck.png";

function Brands() {
  return (
    <div className="pt-3 bg-main-ins">
      <div
        className="shapedividers_com-9844"
        style={{ marginTop: "-50px", scale: "2.4", overflow: "hidden" }}
      >
        <div className="mx-auto text-center header-arrow">
          <img
            src={arrow}
            alt="arrow"
            style={{
              maxHeight: "17px",
              rotate: "90deg",
              marginTop: "-17px",
              filter: "contrast(0%) brightness(200%)",
            }}
          />
        </div>
      </div>

      <div className="container mt-5 row mx-auto pb-5">
        <div
          className="col-4 mx-auto text-center mt-2 a-img pb-4"
          style={{ marginTop: "-50px" }}
        >
          {/* Link ile ilgili sayfaya yönlendirme sağlanıyor */}
          <Link className="a-img" to="/AirFreight">
            <img src={plane} className="a-img" alt="Plane" />
            <p className="text-primary fw-bold fs-6">Hava Taşımacılığı ©Mers</p>
          </Link>
        </div>

        <div
          className="col-4 mx-auto text-center mt-2 a-img pb-4"
          style={{ marginTop: "-50px" }}
        >
          <Link className="a-img" to="/GroundFreight">
            <img src={truck} className="a-img" alt="Truck" />
            <p className="text-primary fw-bold fs-6">Kara Taşımacılığı ©Mers</p>
          </Link>
        </div>

        <div
          className="col-4 mx-auto text-center mt-2 a-img pb-4"
          style={{ marginTop: "-50px" }}
        >
          <Link className="a-img" to="/OceanFreight">
            <img src={ship} className="a-img" alt="Ship" />
            <p className="text-primary fw-bold fs-6">Deniz Taşımacılığı ©Mers</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Brands;
