import React, { forwardRef, useImperativeHandle, useRef } from "react";

const VideoPlayer = forwardRef(({ onVideoEnd }, ref) => {
  const videoRef = useRef(null);

  // Video elementine dışarıdan erişim sağlamak için useImperativeHandle kullanıyoruz
  useImperativeHandle(ref, () => ({
    toggleMute: () => {
      if (videoRef.current) {
        videoRef.current.muted = !videoRef.current.muted;
      }
    },
    getMuted: () => {
      return videoRef.current ? videoRef.current.muted : false;
    },
  }));

  const handleContextMenu = (e) => {
    e.preventDefault(); // Sağ tıklamayı engelle
  };

  return (
    <div
      onContextMenu={handleContextMenu}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        zIndex: 100,
      }}
    >
      <video
        id="intro"
        ref={videoRef}
        autoPlay
        playsInline
        muted
        onEnded={onVideoEnd}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      >
        <source src="/video/istanbulsesli.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
});

export default VideoPlayer;