// airfreight.js
import React from "react";
import { Link } from "react-router-dom"; // Yönlendirme için React Router'dan Link bileşeni
// Gerekli görseller (varsa kendi projenizdeki görselleri kullanın)

import BlogsList from "../components/BlogsList";
import Headers from "../components/Header";
import Footer from "../components/Footer";
import Slider from "../components/Slider";




function Blogs() {
    
  return (

    <div>
    <Headers/> 
    <Slider/>    
    <BlogsList/>
       <section className="cta-section bg-primary text-center py-5">
            <div className="container">
              <h2 className="text-light">Ready to Ship?</h2>
              <p className="text-light">Contact us today and let our experts take care of your air freight needs.</p>
              <Link to="/contact" className="btn btn-light text-primary">
                Contact Us
              </Link>
            </div>
          </section>
    <Footer/>
    </div>
    
  );
}

export default Blogs;
