
// import SolutionsImg from "../assets/Ocean Freight/large-cargo-ship-with-containers-port-generative-ai.jpg";
// import Logo1 from "../assets/Brands/logo.png";
// import Logo2 from "../assets/Brands/logo2.png";
// import Logo3 from "../assets/Brands/logo3.png";
// import Logo4 from "../assets/Brands/logo4.png";
// import Logo5 from "../assets/Brands/logo5.png";
// import Logo6 from "../assets/Brands/logo6.png";
// import Logo7 from "../assets/Brands/logo7.png";
// import Logo8 from "../assets/Brands/logo8.png";
// import Logo9 from "../assets/Brands/logo9.png";
import airFreight from '../assets/AirFreight/Air_Freight.mp4';
import Ocean from '../assets/Ocean Freight/shipping-industry-carries-cargo-large-container-ships-generative-ai.jpg';
import Ground from '../assets/GroundFreight/trucks-highway-mountain-sunset.jpg';
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";

function RequestOfferModal({ show, handleClose }) {


  const [formData, setFormData] = useState({
    businessName: "",
    businessAddress: "",
    fullName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://mail.grupmers.com/send_email.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (result.status === "success") {
        alert("Teklif isteğiniz başarıyla gönderildi!");
        handleClose(); // Modalı kapat
      } else {
        alert("Bir hata oluştu: " + result.message);
      }
    } catch (error) {
      console.error("İstek gönderilirken hata oluştu:", error);
      alert("Bir hata oluştu, lütfen tekrar deneyin.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title className="text-primary fw-bold">Bir Teklif Alın</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" required>
          <Form.Label>İşletme ismi</Form.Label>
          <Form.Control
            type="text"
            name="businessName"
            placeholder="İşletme ismi..."
            value={formData.businessName}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>İşletme Adresi</Form.Label>
          <Form.Control
            type="text"
            name="businessAddress"
            placeholder="İş yeri adresi..."
            value={formData.businessAddress}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>İsim Soyisim</Form.Label>
          <Form.Control
            type="text"
            name="fullName"
            placeholder="İsim soyisim giriniz..."
            value={formData.fullName}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>E-Posta</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="e-posta adresinizi giriniz..."
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Telefon numarası</Form.Label>
          <Form.Control
            type="tel"
            name="phone"
            placeholder="Numaranızı giriniz..."
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Mesajınız</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="message"
            placeholder="Mesajınızı yazınız..."
            value={formData.message}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleClose}>
            Kapat
          </Button>
          <Button variant="outline-primary" type="submit">
            Gönder
          </Button>
        </Modal.Footer>
      </Form>
    </Modal.Body>
  </Modal>
  );
}

function Markers() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="row align-items-center pb-5 pt-5 bg-main-ins">
      <div className="container mx-auto rounded pb-5">
        <div className='mx-auto col-12 text-center'>
          <h1 className="mx-auto text-center pb-1 highlighted-text fs-3 fw-bold'">Önemse, Birleş, Hareket et</h1>
          <div className="row container col-md-6 mx-auto mt-5 pb-5">
            <div className="col-12 col-md-6 d-flex justify-content-center mx-auto">
              <Button variant="primary" onClick={() => setModalShow(true)} className="btn btn-lg btn-primary border-warning rounded-0 fw-semibold text-start w-100">
                Bir Teklif Alın
              </Button>
            </div>
          </div>

          <div className='bg-main-ins'>
            <div className='container col-10 col-md-8 mx-auto rounded p-2 pt-5 row card-hover'>
              <div className='mx-auto col-12 text-center'>
                <h1 className='mx-auto text-center pb-1 highlighted-text fs-2 fw-semibold'>Hizmetlerimiz</h1>
                <h2 className='mx-auto text-center fs-2 text-dark'>Dünyaya Dokunun</h2>
                <p className='mx-auto text-center fw-medium mb-5 col-12 col-md-8'>Global çapta en güvenilir ve hızlı taşıma hizmetlerini gerçekleştirdiğimiz ve yüzlerce sektöre hizmet verdiğimiz yöntemlerimiz.</p>
              </div>

              <div className='col-12 col-md-4 mx-auto p-2 bg-main-ins shadow mt-4 row mb-md-5' style={{ border: "#00000000 1px solid", borderRadius: "10px" }}>
                <Link className="mt-0 p-0" style={{ textDecoration: 'none' }} to="/GroundFreight">
                  <img src={Ocean} alt="logistic_image" style={{ borderRadius: "5px", width: "100%", height: "220px", objectFit: "cover" }} />
                  <h1 className='mx-auto text-center fs-4 pt-4'>Ocean Freight</h1>
                  <p className='px-4 fw-medium'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                  <button className='btn btn-outline-primary mx-auto text-center col-6 p-2 mt-4 mb-3'>Detaylar</button>
                </Link>
              </div>

              <div className='col-12 col-md-4 mx-auto p-2 bg-primary shadow mt-4 mt-md-2 row mb-md-5' style={{ border: "#00000000 1px solid", borderRadius: "10px" }}>
                <Link className="mt-0 p-0" style={{ textDecoration: 'none' }} to="/AirFreight">
                  <video src={airFreight} alt="logistic_image" autoPlay  loop={true} playsInline style={{ borderRadius: "5px", width: "100%", height: "220px", objectFit: "cover" }} />
                  <h1 className='mx-auto text-center text-light fs-4 pt-2'>Hava Taşımacılığı</h1>
                  <p className='px-4 text-light fw-medium'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                  <button className='btn btn-outline-light mx-auto text-center col-6 mt-4 mb-3'>Detaylar</button>
                </Link>
              </div>

              <div className='col-12 col-md-4 mx-auto p-2 bg-main-ins shadow mt-4 row mb-md-5' style={{ border: "#00000000 1px solid", borderRadius: "10px" }}>
                <Link className="mt-0 p-0" style={{ textDecoration: 'none' }} to="/GroundFreight">
                  <img src={Ground} alt='logistic_image' autoPlay  loop={true} playsInline style={{ borderRadius: "5px", width: "100%", height: "220px", objectFit: "cover" }} />
                  <h1 className='mx-auto text-center fs-4 pt-4'>Kara Taşımacılığı</h1>
                  <p className='px-4 fw-medium'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                  <button className='btn btn-outline-primary mx-auto text-center col-6 p-2 mt-4 mb-3'>Detaylar</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RequestOfferModal show={modalShow} handleClose={() => setModalShow(false)} />
    </div>
  );
}

export default Markers;