import React from "react";
import img1 from "../assets/Ocean Freight/container-operation-port-series.jpg"





function News (){
    


    return(

        <div className="Maps bg-main ">
         <div className='mx-auto col-12 pt-5 text-center' >
         <h1 className='mx-auto text-center pb-1 highlighted-text fs-2 fw-medium'  >  </h1>
         </div>
        <div className="container mx-auto p-5" style={{
            marginTop:"-220px"
        }}>
            <div className="row mx-auto">
                <div className="col-md-4 col-12 px-4 mx-auto text-center ">
                
                    <img src={img1} alt="logistic_image" className="img-thumbnail" style={{height:"270px", objectFit:"cover"}} />
                    <p className="text-primary fs-5 fw-bold mt-4"style={{lineHeight:"25px"}}>Using Market Intelligence to Gain a Competitive Edge in Logistics</p>
                    <p className="text-dark fw-thin mt-4">Using Market Intelligence to Gain a Competitive Edge in Logistics to Gain a Competitive Edge in Logistics</p>
              
                </div>
                <div className="col-md-4 col-12 px-4 mx-auto text-center ">
                
                    <img src={img1} alt="logistic_image" className="img-thumbnail" style={{height:"270px", objectFit:"cover"}} />
                    <p className="text-primary fs-5 fw-bold mt-4" style={{lineHeight:"25px"}}>Using Market Intelligence to Gain a Competitive Edge in Logistics</p>
                    <p className="text-dark fw-thin mt-4">Using Market Intelligence to Gain a Competitive Edge in Logistics to Gain a Competitive Edge in Logistics</p>
                
                </div>
                <div className="col-md-4 col-12 px-4 mx-auto text-center ">
                
                    <img src={img1} alt="logistic_image" className="img-thumbnail" style={{height:"270px", objectFit:"cover"}} />
                    <p className="text-primary fs-5 fw-bold mt-4" style={{lineHeight:"25px"}}>Using Market Intelligence to Gain a Competitive Edge in Logistics</p>
                    <p className="text-dark fw-thin mt-4">Using Market Intelligence to Gain a Competitive Edge in Logistics to Gain a Competitive Edge in Logistics</p>
                
                </div>
            </div>
        </div>


        </div>



    )
}


export default News