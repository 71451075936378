import  React, { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from '../src/pages/Home';
import Blogs from '../src/pages/Blogs'; 
import Contact from '../src/pages/Contact'; 
import backgroundSVG from "./assets/HeaderBackground.svg";
import VideoPlayer from "./components/VideoPlayer";
import Rotate from "./assets/rotate.mp4";
import AirFreight from "./pages/AirFreight";
import ServicesPage from "./pages/ServicesPage";
import OceanFreight from "./pages/OceanFreight";
import Career from "./pages/Career";
import AboutUs from "./pages/AboutUs";
import BlogDetail from "./components/BlogDetail";

import 'animate.css';
import './App.css';
import './input.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './carousel.css';

const App = () => {
  const [showSpinner, setShowSpinner] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const [videoFadeOut, setVideoFadeOut] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    setIsLandscape(window.innerWidth > window.innerHeight);
  }, []);

  useEffect(() => {
    console.log("showVideo State:", showVideo);
  }, [showVideo]);

  useEffect(() => {
    console.log("Spinner started");
    const spinnerTimeout = setTimeout(() => {
      console.log("Spinner finished, starting video");
      setShowSpinner(false);
      setShowVideo(true);
    }, 3500);

    const handleOrientationChange = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };
    window.addEventListener("resize", handleOrientationChange);

    return () => {
      clearTimeout(spinnerTimeout);
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  const handleVideoEnd = () => {
    console.log("Video ended, showing content with fade-out");
    setVideoFadeOut(true);
    setTimeout(() => {
      setShowVideo(false);
    }, 1000);
  };

  const toggleAudio = () => {
    if (videoRef.current) {
      videoRef.current.toggleMute();
      setAudioEnabled(!videoRef.current.getMuted());
      console.log("ses")
    }
  };

  if (showVideo && !isLandscape) {
    return (
      <div className="rotate-warning">
        <video loop={true} autoPlay muted playsInline style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}>
          <source src={Rotate} type="video/mp4" />
        </video>
        <h2 style={{
          position: "absolute",
          top: "50%",
          left: "26%",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          fontSize: "3vw"
        }}>Lütfen cihazınızı yatay moda çevirin</h2>
      </div>
    );
  }

  return (
    <BrowserRouter basename="/">
      <div className={showVideo ? "" : "App-container"} style={{ position: "relative", backgroundImage: showVideo ? "none" : `url(${backgroundSVG})` }}>
        {showSpinner && (
          <div className="loading_bar">
            <div className="spinner-container" style={{ width: "250px", height: "250px" }}>
              <div className="spinner">
                <div className="spinner">
                  <div className="spinner">
                    <div className="spinner">
                      <div className="spinner">
                        <div className="spinner"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {showVideo && (
          <div style={{ opacity: videoFadeOut ? 0 : 1, transition: "opacity 1s ease-in-out", position: "relative" }}>
            <VideoPlayer ref={videoRef} onVideoEnd={handleVideoEnd} />

            <button
              className="audio-toggle-button"
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: 1000,
                padding: "10px",
                fontSize: "16px",
                backgroundColor: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={toggleAudio}
            >
              {audioEnabled ? "🔊 Ses Açık" : "🔇 Ses Kapalı"}
            </button>

            <button
              className="audio-toggle-button"
              style={{
                position: "absolute",
                top: "60px",
                right: "10px",
                zIndex: 1000,
                padding: "10px",
                fontSize: "16px",
                backgroundColor: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                console.log("Video skipped, showing content");
                setVideoFadeOut(true);
                setTimeout(() => {
                  setShowVideo(false);
                }, 1000);
              }}
            >
              Videoyu Atla
            </button>
          </div>
        )}

        {!showSpinner && !showVideo && (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Airfreight" element={<AirFreight />} />
            <Route path="/ServicesPage" element={<ServicesPage />} />
            <Route path="/Oceanfreight" element={<OceanFreight />} />
            <Route path="Blogs" element={<Blogs />} />      
            <Route path="/blog/:id" element={<BlogDetail />} />      
            <Route path="AboutUs" element={<AboutUs />} />
            <Route path="Career" element={<Career />} />
            <Route path="Contact" element={<Contact />} />
            {/* <Route path="*" element={<NoPage />} /> */}
          </Routes>
        )}
      </div>
    </BrowserRouter>
  );
};

export default App;