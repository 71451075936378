import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

function BlogDetail() {
  const { id } = useParams(); // URL'den blog ID'sini al
  const [post, setPost] = useState(null);
  const imageSrc = "../../assets/";

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch("/blogs.json");

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const foundPost = data.posts.find((p) => p.id.toString() === id);

        if (foundPost) {
          setPost(foundPost);
        } else {
          setPost(null);
        }
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    };

    fetchPost();
  }, [id]);

  if (!post) {
    return <h2 className="text-center text-danger">Blog bulunamadı!</h2>;
  }

  return (<>
    <Header/>
    <div className="bg-light">
    <div className=" container">
      <div className=" mx-auto pt-5 ">
        <img
          className="mt-5 my-5 rounded"
          src={`${imageSrc}${post.image}`}
          alt={post.title}
          style={{ objectFit: "cover", width: "100%", maxHeight: "600px", height: "600px" }}
        />
        <div className="">
          <h2 className="text-primary text-center">{post.title}</h2>
          <p className="text-center text-dark">{post.content}</p>
          <p className="text-center text-dark">{post.bigContent}</p>
        </div>
        <div className="text-center">
          <Link to="/Blogs" className="btn btn-outline-primary">Geri Dön</Link>
        </div>
      </div>
    </div>
    </div>

     {/* CALL-TO-ACTION (CTA) BÖLÜMÜ */}
          <section className="cta-section bg-light text-center py-5">
            <div className="container">
              <h2>Ready to Ship?</h2>
              <p>Contact us today and let our experts take care of your air freight needs.</p>
              <Link to="/contact" className="btn btn-primary">
                Contact Us
              </Link>
            </div>
          </section>
<Footer/>
    </>
  );

}

export default BlogDetail;
